.general-style {
  color: white;
  margin-top: 4em;
  .title {
    font-size: 24px;
  }
  .div-container {
    line-height: 0.5;
    padding: 0% 16% 0% 16%;
    justify-content: space-between;
    display: flex;
    .div-row1 {
      text-align: justify;
      p {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.418);
      }
    }
    .div-row2 {
      p {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }
      text-align: end;
    }
  }

  .info {
    font-size: 14px;
    font-weight: bold;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }

  .div-container2 {
    line-height: 1.2;
    padding: 0% 16% 0% 16%;
    justify-content: space-between;
    // justify-content: center;
    display: flex;

    .div-row1 {
      text-align: justify;
      p {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.418);
      }
    }
    .div-row2 {
      p {
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }
      text-align: end;
    }
  }

  .div-container3 {
    padding: 0% 16% 0% 16%;
    justify-content: space-between;
    // justify-content: center;
    display: flex;

    .div-row1 {
      text-align: justify;
      p {
        line-height: 1;
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.418);
      }
    }
    .div-row2 {
      p {
        line-height: 1.3;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }
      text-align: end;
    }
  }
  .div-container4 {
    padding: 0% 16% 0% 16%;
    justify-content: space-between;
    // justify-content: center;
    display: flex;

    .div-row1 {
      text-align: justify;
      p {
        font-size: 12px;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.418);
      }
    }
    .div-row2 {
      p {
        margin: 1.2em;
        font-size: 12px;
        font-weight: 600;
        color: #ffffff;
      }
      text-align: end;
    }
  }

  .txt-second-slide {
    margin-top: 3em;
    margin-bottom: 3em;
    line-height: 0.2;
    .title2 {
      font-size: 14px;
      font-weight: bold;
    }
    .subtitle2 {
      color: rgba(255, 255, 255, 0.486);
      font-size: 12px;
      font-weight: 400;
    }
  }
}
