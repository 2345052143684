.color-system {
  margin: 2%;
  width: 40%;
  border: 1px solid black;
  background-color: white;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
    padding: 4% 0px 0px 5%;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
  .container-colors {
    .title-p {
      font-weight: 600;
      font-size: 18px;
      line-height: 25px;

      margin-left: 2%;
      text-align-last: left;
    }
    .colors {
      padding: 10%;
      text-align: left;
    }
  }
}
