.logo-location {
  margin: 2%;
  width: 80%;
  border: 1px solid black;
  background-color: #353535;
  border-radius: 15px;
  @media (max-width: 600px) {
    width: 96%;
  }
  .mini-nav {
    border-top-left-radius: 15px 15px;
    border-top-right-radius: 15px 15px;
    height: 5vh;
    text-align-last: left;
    padding: 2% 0px 0px 2%;
    background-color: black;
    p {
      color: white;
      margin: 0 !important;
    }
  }
.images{
    display: flex;
    .div-img{
        padding: 2% !important;
        p{
            margin-left: 1em;
            font-weight: 600;
            font-size: 15px;
            line-height: 25px;
      
            color: #6774dc;
        }
        img {
        
            width: 80%;
          }
    }
    
}
 
}
