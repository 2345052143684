.div-input-style {

    .input-2 {
      width: 251px;
 
      padding: 1%;
      border: 2px solid #ffffff;

      border-radius: 10px;
      background-color: transparent;
  
      @media (max-width: 600px) {
    
        width: 100px;
        padding: 2%;
      }
    }
    .radio-btn{
        margin-left: 2%;
        color:rgba(255, 255, 255, 0.705) !important;
    }
    // .error-style{

    // }
  }
  .div-input-style-3{

    .input-2 {
      width: 151px;
 
      padding: 1%;
      border: 2px solid #ffffff;

      border-radius: 10px;
      background-color: transparent;
  
      @media (max-width: 600px) {
    
        width: 80px;
        padding: 2%;
      }
    }
    .radio-btn{
        margin-left: 2%;
        color:rgba(255, 255, 255, 0.705) !important;
    }
    // .error-style{

    // }
  }
  