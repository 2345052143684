.pay-style {
    margin-top: 9em;
  color: white;
  p {
    font-weight: 600;
    font-size: 20px;
    line-height: 20px;

    text-align: center;

    color: #ffffff;

    text-shadow: 0px 2px 1px #000000;
  }
  button {
      margin: 3em 0em 4em 0em;
    background-color: transparent;
    border: 1px solid transparent;
    .btn-div {
        background-position-x: center;
      width: 13em;
      height: 18vh;
      background-image: url("./../../assets/pse\ 4.png");
      object-fit: cover;
    }
  }
}
