.txt-label {
  color: white;
  font-weight: 600;
}

.txt-welcomeMessage {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-sub {
  font-size: 24px;
}

.txt-welcome {
  color: white;
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
    background-color: white !important;
    background: white !important;
  }
}

.checkbox-style {
  color: white !important;
  font-family: "Open Sans" !important;
  font-weight: bold !important;
  align-self: center !important;
}

.form-style {
  color: white !important;
  font-family: "Open Sans" !important;
  font-weight: bold !important;
  margin-left: 10vw !important;
  margin-right: 10vw !important;
}

.select-stuff {
  border-color: white !important;
  color: white;
  fill: white;
}

MuiSelect-select {
  border-color: white;
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: white;
  color: white;
}

.txt-title {
  font-family: OpenSans-Regular;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  /* or 111% */

  text-align: center;

  color: #ffffff;
}

.btn-more {
  text-align: -webkit-center;

  p {
    width: 244px;

    font-family: OpenSans-Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* or 100% */

    text-align: center;

    color: rgba(255, 255, 255, 0.5);
  }
}
