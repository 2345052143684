.requirements{
    
    color:white;
   .title{
       margin-top:5em;
        font-size: 24px;
        font-weight: bold;
    }
    img{
        margin-top:4em;  
    }
    p{
        margin-top:5em; 
        font-weight: 500;
        font-size: 16px;
    }
    .btn-req{
        margin:4em 0em 4em 0em;  
    }
}