.impronta-advice{
    padding:5%;
    color: white;
    .text-1{
        text-shadow: 2px 2px #050505;
        font-weight:400;
        font-size: 24px;
        margin-top: 2em;
    }
    .text-2{
        text-shadow: 2px 2px #050505;
        font-weight:900;
        font-size: 28px;
        margin-top: 1em;
    }
    .text-3{
        font-weight:600;
        font-size: 24px;

    }
    .text-4{
        margin-top: 3em;
        font-size: 12px;
        color: #ffffff94;
        font-weight:400;

    }
}