.hfMaster {
  --fontColorPrimary: "#ffffff";
}

.img-logo {
  @media (max-width: 600px) {
    width: 80vw;
  }

  @media (min-width: 601px) {
    width: 400px;
  }
}

.txt-label2 {
  color: var(--fontColorPrimary);
  font-weight: 300;
  font-size: 20px;
}

@media (min-width: 480px) {
  .show-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
@media (max-width: 480px) {
  .show-message {
    margin-top: 10vh;
    margin-bottom: 50px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.txt-validation-hf {
  color: red;
  font-weight: 600;
  font-size: 14px;
  // mix-blend-mode: difference;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.txt-label-title-hf {
  color: var(--fontColorPrimary);
  font-weight: 600;
  font-size: 26px;
}

.txt-welcomeMessage-hf {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-welcome-hf {
  color: var(--fontColorPrimary);
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
  }
}

.checkbox-style {
  color: var(--fontColorPrimary) !important;
  font-family: "OpenSans-Regular" !important;
  font-weight: bold !important;
  align-self: center !important;
}

// .form-style {
//   color: white !important;
//   font-family: "OpenSans-Regular" !important;
//   font-weight: bold !important;
//   margin-left: 10vw !important;
//   margin-right: 10vw !important;
// }

.select-stuff {
  border-color: white !important;
  color: white;
  fill: white;
}

MuiSelect-select {
  border-color: white;
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: white;
  color: white;
}

// .datePicker {
//   // color: white;
//   input {
//     color: white;
//     width: 100%;
//     border-radius: 10px;
//     border-color: white;
//     border-style: solid;
//     border-width: 1px;
//     background-color: transparent;
//     margin-left: 0;
//     height: 40px;
//     font-size: 1rem;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   }
// }

.muiinputstuff {
  input {
    color: white;
    border-color: white !important;
  }
  MuiInputBase-root {
    color: white;
  }
  color: white;
}

.img-flecha-inv {
  transform: rotate(180deg);
}

.separated {
  padding-left: 15px;
  letter-spacing: 42px;
  border: 0;
  background-image: linear-gradient(
    to left,
    black 70%,
    rgba(255, 255, 255, 0) 0%
  );
  background-position: bottom;
  background-size: 50px 1px;
  background-repeat: repeat-x;
  background-position-x: 35px;
  width: 220px;
  outline: none;
}

.otpInput {
  border: 1px solid var(--fontColorPrimary);
  background-color: transparent;
  margin-right: 10px;
  size: 30px;
  height: 30px !important;
  width: 30px !important;
  color: var(--fontColorPrimary);
  border-radius: 10px;
  flex: 1;
  justify-content: center;
}

.otpContainer {
  // display: inline !important;
  justify-content: center;
}
