.logo-style {
    text-align: right;
  line-height: 2px;
  p {

    margin-top: 6em;

    font-family: OpenSans-Regular;
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 19px;
    text-align: center;

    font-style: normal;
    color: #ffffff;
  }

    img{

        margin-bottom: 40em;
        margin-right:5em;
    }


}
