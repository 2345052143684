.select-option {
  color: white;
  height: 17vh;
    // width: 80%;
  padding: 2%;
  fieldset {
    text-align: left;
    border: 1px solid #ffffff21;
    border-radius: 15px;

  }

  fieldset legend {
    font-weight: 900;
    font-size: 18px;
    color: #fff;
    padding: 5px 10px;
  }
  .select-text {
   padding: 2%;
    text-align: center;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 4%;
  }
}
