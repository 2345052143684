.screen-success{
    margin-top:14em;
    color: white;
    img{
        width: 130px;
    }
    p{
        text-shadow: 2px 2px #050505;
        font-weight:400;
        font-size: 20px;
    }
}