.frm2 {
  color: white;
  padding: 0% 8% 8% 8%;
  .title {
    margin-left: 7%;

    text-shadow: 0px 1px 1px #000000;

    font-weight: 300;
    font-size: 18px;
  }
  .div-container {
    margin-left: 4em;

    display: flex;
    text-align: justify;

    font-weight: 300;
    font-size: 12px;

    text-shadow: 0px 1px 1px #000000;
  }
  .sign {
    .sign-pro {
      font-weight: 300;
      font-size: 12px;

      text-align: center;

      text-shadow: 0px 1px 1px #000000;
    }
    margin-top: 2em;
    margin-left: 4em;
    font-weight: bold;
    font-size: 9px;

    text-align: center;
    text-shadow: 0px 1px 1px #000000;
  }
}
