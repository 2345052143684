.final {
  color: white;
  padding: 8%;
  input{
    border: 1px solid transparent;
    text-align: center;
    color: white ;
    background:  rgba(255, 255, 255, 0.08);
    border-radius: 8px;
    font-size: 18px;
  }
  .title {
    font-weight: 300;
    font-size: 20px;

    text-shadow: 0px 1px 1px #000000;
  }
  .div-cont{
  margin-top: 13%;
    .center-title {
      line-height: 0.1;
      font-weight: 300;
      font-size: 14px;
  
      text-shadow: 0px 1px 1px #000000;
    }
    .sub-title {
      font-weight: 300;
      font-size: 24px;
  
      text-shadow: 0px 1px 1px #000000;
    }
  }
  .div-cont-other{
    margin-top: 13%;
      .center-title {
        line-height: 0.1;
        font-weight: 300;
        font-size: 14px;
    
        text-shadow: 0px 1px 1px #000000;
      }
 input{
   border: 1px solid transparent;
   text-align: center;
   color: white ;
   background:  rgba(255, 255, 255, 0.08);
   border-radius: 8px;
   font-size: 18px;
 }
    }

}
