
.page-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
  padding: 10px;
  margin: 10px;
  height: 100%;
  
}
.page {
    flex: 0 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  width: 400px;
  height: 700px;
  margin: 10px;
  display: inline-block;
  justify-content: center;
  overflow-y: scroll;
  
  padding: 20px;
}
.field {
  border-style: solid;
  border-width: 1px;
  border-color: white;
  margin: 10px;
  padding: 10px;
}
