.tecno-style {
  padding: 4%;
  color: white;
  // -----------------------STYLE CHECKBOX FIX ----------------------------------------
 

  .row-1 {
    display: flex;
  }
  // -----------------------STYLE CONTAINER 1 AND 2----------------------------------------
  .container1 {
    margin-top: 2em;
    .row-title1 {
      padding-left: 8%;
      text-align: justify;
      line-height: 4px;
     
        .p1 {
          font-size: 22px;
          font-weight: 700;
        }
        .p2 {
          color: rgba(255, 255, 255, 0.267);
          font-size: 11px;
          font-weight: 600;
        }
      
     
    }

    .row-1 {
      .p1 {
        color: rgba(255, 255, 255, 0.267);
        font-size: 12px;
        font-weight: 600;
      }
      .p2 {
        font-size: 12px;
        font-weight: 600;
      }
    }
  }
  // -----------------------STYLE CONTAINER 3----------------------------------------
  .container2 {
    margin-top: 2em;
    .row-title2 {
      padding-left: 8%;
      text-align: justify;
      line-height: 10px;
      .p1 {
        font-size: 22px;
        font-weight: 700;
      }
      .p2 {
        color: rgba(255, 255, 255, 0.267);
        font-size: 11px;
        font-weight: 600;
      }
    }

    .row-2 {
      .texts{
        width:80%;
      .p1 {
        margin-left: 10%;
        text-align: justify;

        color: rgba(255, 255, 255, 0.267);
        font-size: 9px;
        font-weight: 600;
      }}
      .p1 {
        margin-left: 10%;
        text-align: justify;

        color: rgba(255, 255, 255, 0.267);
        font-size: 9px;
        font-weight: 600;
      }
    }
  }
  // -----------------------STYLE CONTAINER 4 AND 5----------------------------------------
  .container4 {
    margin-top: 2em;
    .row-title4 {
      padding-left: 8%;
      text-align: justify;
      line-height: 8px;
      .p1 {
        font-size: 22px;
        font-weight: 700;
      }
      .p2 {
        color: rgba(255, 255, 255, 0.267);
        font-size: 11px;
        font-weight: 600;
      }
    }

    .row-4 {
      .p1 {
        margin-left: 10%;
        text-align: justify;

        color: rgba(255, 255, 255, 0.267);
        font-size: 9px;
        font-weight: 600;
      }

      .p2 {
        padding-left: 3em;
        text-align: justify;
        font-size: 10px;
        font-weight: 700;
      }
    }
  }
}
