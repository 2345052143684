.container {
  margin: 2%;
  text-align: center;
  .title {
    font-family: OpenSans-Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    /* or 100% */

    color: #ffffff;
  }
  .container-inputs {
    margin-right: 16%;
  }
}
